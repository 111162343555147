import { PortalRequest as request } from "@/utils/request";

// 新增关注
export const getAddFocus = (data) => {
    return request({
        url: "/focus/save",
        method: "post",
        data,
    });
};

// 分页查看我的关注
export const getFocusList = (params,data) => {
    return request({
        url: "/focus/page-list-me",
        method: "post",
        params,
        data,
    });
};

// 根据关注id删除关注
export const getFocusRemove = (id) => {
    return request({
        url: `/focus/${id}/remove`,
        method: "delete",
    });
};

// 根据租户id和记录id获取是否已关注
export const getIsFocus = (id) => {
    return request({
        url: `/focus/${id}/has-focus`,
        method: "get",
    });
};